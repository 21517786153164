<!-- 个人中心 -->
<template>
  <div class="job_fair_detail">

    <header>
      <Button size="small" type="warning" @click="$router.back()">
        <Icon type="ios-arrow-back" />
        返回
      </Button>
      <Divider type="vertical" />
      <p>{{this.$route.meta.title}}</p>
    </header>
    <div style="background:#FFFFFF;padding-top:32px;padding-bottom:27px;  position: relative;">
      <Spin size="large" fix v-if="spinShow"></Spin>
      <div>
        <div class="list_item">
          <div class="list_item_left">应聘者：</div>
          <div class="list_item_right">
            <input type="text" v-model="itr_user" readonly />
          </div>
        </div>
        <div class="list_item">
          <div class="list_item_left">面试方式：</div>
          <div class="list_item_right">
            <input type="text" v-model="itr_type" readonly />
          </div>
        </div>
        <div class="list_item">
          <div class="list_item_left">面试时间：</div>
          <div class="list_item_right">
            <input type="text" v-model="itr_time" readonly />
          </div>
        </div>
        <div class="list_item">
          <div class="list_item_left">联系方式：</div>
          <div class="list_item_right">
            <input type="text" v-model="itr_conect" readonly />
          </div>
        </div>
        <div class="list_item">
          <div class="list_item_left">面试地点：</div>
          <div class="list_item_right">
            <input type="text" v-model="itr_address" readonly />
          </div>
        </div>
        <div class="list_item">
          <div class="list_item_left">面试职位：</div>
          <div class="list_item_right" style="position: relative;">
            <input type="text" v-model="itr_position" readonly />
            <div style="position: absolute;right:15px;top:10px;color:#009DFF;cursor:pointer;" @click="positionDetail">详情</div>
          </div>
        </div>
        <div class="list_item">
          <div class="list_item_left">注意事项：</div>
          <div class="list_item_right">
            <textarea name="company_text" v-model="itr_description" style="width:410px;padding-left:12px;border: 1px solid #e4e7ed;height:128px;" cols="56" rows="4" readonly></textarea>
          </div>
        </div>
      </div>

    </div>

  </div>

</template>

<script>
export default {
  data() {
    return {
      spinShow: false,
      itr_user: '',
      itr_type: '',
      itr_time: '',
      itr_conect: '',
      itr_address: '',
      itr_position: '',
      itr_description: '',

      id: '',
      type: '',
      _id: ''

    }
  },

  methods: {
    //退出登录
    out() {
      localStorage.removeItem("firm_mobile")
      localStorage.removeItem("firm_token")
      localStorage.removeItem("firm_enterpriseId")
      this.$router.push('/login')
    },
    //封装获取数据
    get_data() {
      this.spinShow = true
      this.app('enterpriseOperators', 'getInterviewsByResumeId', {
        mobile: localStorage.getItem('firm_mobile'),
        resumeId: this.$route.query.id
      }).then(res => {
        this.spinShow = false

        console.log(res);

        var result = res.data;
        this.itr_user = result.personal.name
        this.itr_type = result.method
        this.itr_time = result.date
        this.itr_conect = result.contact
        this.itr_address = result.address
        this.itr_position = result.position.name
        this.itr_description = result.matters

        this.id = result.position.id
        this.type = result.position.type

        this._id = result.position._id
      })

    },

    // 职位详情
    positionDetail() {
      if (this.type == 1) {
        // this.$router.push({name:'position',params: {id:this.id}})
        this.$router.push({ name: 'job_full', query: { id: this._id } })
      } else {
        // this.$router.push({ name: 'hourposition', params: { id: this.id } })
        this.$router.push({ name: 'job_Temporary', query: { id: this._id } })
      }
    }



  },
  created() {
    this.get_data()
  }
}
</script>

<style lang="less" scoped>
header {
  display: flex;
  align-items: center;
  // width: 740px;
  // height: 72px;
  padding: 20px 0;
  margin-bottom: 14px;
  background: #ffffff;
  padding-left: 30px;
}

.list_item {
  justify-content: center;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.list_item_left {
  width: 100px;
  height: 40px;
  line-height: 40px;
  text-align: left;
  font-size: 14px;
  color: #606266;
  margin-right: 12px;
}
.list_item_right {
  width: 410px;
}
.list_item_right input {
  width: 410px;
  height: 40px;
  opacity: 1;
  background: #ffffff;
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  padding-left: 12px;
}
</style>
